import styles from './DeleteDocumentModal.module.scss';
import { PropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../../components/common/Modal/Modal';
import Button from '../../../../../components/common/Button/Button';
import { ReactComponent as CloseIcon } from '../../../../../icons/close.svg';

interface DeleteDocumentModalProps {
  oemId: number | null;
  productId: number | null;
  onDelete: (oemId: number | null, productId: number | null) => void;
  onClose: () => void;
}

function DeleteDocumentModal({
  onDelete,
  onClose,
  oemId,
  productId,
}: PropsWithoutRef<DeleteDocumentModalProps>) {
  return (
    <Modal
      onClose={onClose}
      hideClose={true}
      center={true}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <CloseIcon onClick={onClose} className={styles.close} />
        <div className={styles.title}>
          <FormattedMessage id={'delete-document-label'} />
        </div>

        <div className={styles.description}>
          <FormattedMessage id={'are-you-sure-delete-label'} />
        </div>

        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() => {
              // Trigger the delete action
              if (oemId && productId) {
                onDelete(oemId, productId);
              }
            }}
            color={'green'}
            className={styles.deleteButton}
          >
            <FormattedMessage id={'delete-label'} />
          </Button>

          <Button
            onClick={onClose}
            color={'transparent'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteDocumentModal;
