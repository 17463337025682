import { apiInstance } from '../api/requestInterceptor';
import { UploadDocumentResponse } from '../models/upload-document-response';

/**
 * upload document
 */
async function uploadDocument(
  formData: FormData,
): Promise<UploadDocumentResponse> {
  const res = await apiInstance.post<UploadDocumentResponse>(
    '/documents/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
}

async function editDocument(
  oemId: number,
  productId: number,
  docId: number,
): Promise<UploadDocumentResponse> {
  const res = await apiInstance.post<UploadDocumentResponse>(
    `/documents/editDoc/${docId}/${oemId}/${productId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return res.data;
}

async function deleteDocument(
  oemId: number,
  productId: number,
  docId: number,
): Promise<UploadDocumentResponse> {
  const res = await apiInstance.delete<UploadDocumentResponse>(
    `/documents/deletedoc/${docId}/${oemId}/${productId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return res.data;
}

// async function downloadProductDataSheet(
//   id: number,
// ): Promise<UploadDocumentResponse> {
//   const res = await apiInstance.get<UploadDocumentResponse>(
//     '/documents/upload',
//     formData,
//     {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     },
//   );
//   return res.data;
// }

export { uploadDocument, editDocument, deleteDocument };
