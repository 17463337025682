import styles from './EditDocumentModal.module.scss';
import { PropsWithoutRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Modal from '../../../../../components/common/Modal/Modal';
import Button from '../../../../../components/common/Button/Button';
import FileInput from '../../../../../components/Admin/FileInput/FileInput';
import DatePicker from '../../../../../components/Admin/DatePicker/DatePicker';
import FormRow from '../../../../../components/Admin/FormRow/FormRow';
import { ReactComponent as CloseIcon } from '../../../../../icons/close.svg';
import { ReactComponent as PinIcon } from '../../../../../icons/pin.svg';
import Input from '../../../../../components/common/Input/Input';
import Select from '../../../../../components/common/Select/Select';
import Checkbox from '../../../../../components/common/Checkbox/Checkbox';
import { RootStore } from '../../../../../store';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

interface EditDocumentModalProps {
  oemId: number | null;
  productId: number | null;
  documentName: string | undefined;
  approvalStatus: string | undefined;
  onEdit: (
    issueDate: Date,
    document: File,
    oemId: number | null,
    productId: number | null,
  ) => void;
  onClose: () => void;
  onChange?: (data: DataItem[]) => void;
}

export interface DataItem {
  oemId: number | null;
  productId: number | null;
  approvalTypeId: number | null;
  active: boolean;
  newDocument: File | null;
  issueDate: Date | null;
  validUntil: Date | null;
  confidential: boolean;
  restrictions: string;
  notes: string;
  group?: number;
}

function EditDocumentModal({
  onEdit,
  onClose,
  oemId,
  productId,
  documentName,
  approvalStatus,
  onChange,
}: PropsWithoutRef<EditDocumentModalProps>) {
  const tableDataDefault: DataItem[] = [
    {
      oemId: oemId,
      productId: productId,
      approvalTypeId: null,
      active: false,
      newDocument: null,
      issueDate: null,
      validUntil: null,
      confidential: false,
      restrictions: '',
      notes: '',
    },
  ];

  const [tableData, setTableData] = useState<DataItem[]>(tableDataDefault);

  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const [newDocumentName, setNewDocumentName] = useState<string>(
    documentName || '',
  );

  const [newApprovalStatus, setNewApprovalStatus] = useState(false);

  const { oemApprovalTypes } = useSelector((state: RootStore) => state.lookups);

  const addOneDay = (dateObj: Date | null): Date | null => {
    if (!dateObj) {
      return null;
    }
    const newDate = new Date(dateObj);
    const newDay = newDate.getDate() + 1;
    newDate.setDate(newDay);
    return newDate;
  };

  const onInputChange = (key: keyof DataItem, index: number, value: any) => {
    const data = cloneDeep(tableData);
    const currentRow = data[index];

    if (key === 'issueDate') {
      const current = addOneDay(value);
      if (
        currentRow['validUntil'] &&
        (current as Date) > currentRow['validUntil']
      ) {
        currentRow['validUntil'] = null;
      }
    }

    if (
      currentRow.group &&
      (key === 'newDocument' ||
        key === 'issueDate' ||
        key === 'validUntil' ||
        key === 'confidential')
    ) {
      const group = data.filter((item) => item.group === currentRow.group);
      group.forEach((item) => {
        (item as any)[key] = value;
      });

      if (key === 'issueDate' && currentRow['validUntil'] === null) {
        group.forEach((item) => (item['validUntil'] = null));
      }
    } else {
      (currentRow as any)[key] = value;
    }

    setTableData(data);
    onChange && onChange(data);
  };

  return (
    <Modal
      onClose={onClose}
      hideClose={true}
      center={true}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <CloseIcon
          onClick={() => {
            setNewDocumentName(documentName || '');
            setNewApprovalStatus(false);
            setTableData(tableDataDefault);
            onClose();
          }}
          className={styles.close}
        />
        <div className={styles.title}>
          <FormattedMessage id={'edit-approval-document-label'} />
        </div>

        <div className={styles.description}>
          <FormattedMessage id={'approval-document-modal-desc'} />
        </div>

        <div className={styles.subTitle}>
          <FormattedMessage id={'add-new-document-label'} />
        </div>

        <div>
          {tableData.map((rowData, index) => (
            <div key={index}>
              <FormRow
                labelId={'approval-type'}
                required={true}
                tooltip={getTranslatedMessage('approval-type')}
              >
                <Select
                  className={styles.selectControl}
                  inputClassName={styles.selectInput}
                  optionClassName={styles.selectOption}
                  value={rowData.approvalTypeId || undefined}
                  options={oemApprovalTypes.map((item) => ({
                    name: String(item.name),
                    value: item.id,
                  }))}
                  onChange={(value) => {
                    setNewApprovalStatus(!newApprovalStatus);
                    onInputChange('approvalTypeId', index, value);
                  }}
                  placeholder={getTranslatedMessage('approval-type')}
                />
              </FormRow>

              <FormRow
                labelId={'status-label'}
                required={true}
                tooltip={getTranslatedMessage('status-label')}
              >
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    checked={rowData.active}
                    onClick={() =>
                      onInputChange('active', index, !rowData.active)
                    }
                  />
                  <div className={styles.checkboxLabel}>
                    <FormattedMessage id={'active-label'} />
                  </div>
                </div>
              </FormRow>

              <FormRow
                labelId={'upload-new-document-label'}
                required={true}
                tooltip={getTranslatedMessage('upload-new-document-tooltip')}
              >
                <FileInput
                  accept={'application/pdf'}
                  file={rowData.newDocument || null}
                  className={styles.fileInput}
                  inputTextClassName={styles.fileInputText}
                  placeholderId={'attach-file-placeholder'}
                  icon={<PinIcon className={styles.pinIcon} />}
                  onChange={(file) => onInputChange('newDocument', index, file)}
                />
              </FormRow>

              <FormRow
                labelId={'issue-date-label'}
                required={true}
                tooltip={getTranslatedMessage('issue-date-label')}
              >
                <DatePicker
                  value={rowData.issueDate || null}
                  className={styles.datePicker}
                  placeholder={'Select Date'}
                  onChange={(value) => onInputChange('issueDate', index, value)}
                />
              </FormRow>

              <FormRow
                labelId={'expiry-date'}
                required={false}
                tooltip={getTranslatedMessage('expiry-date')}
              >
                <DatePicker
                  value={rowData.validUntil || null}
                  className={styles.datePicker}
                  placeholder={'Select Date'}
                  onChange={(value) =>
                    onInputChange('validUntil', index, value)
                  }
                  minDate={addOneDay(rowData.issueDate)}
                />
              </FormRow>

              <FormRow
                labelId={'bp-confidential'}
                required={false}
                tooltip={getTranslatedMessage('bp-confidential')}
              >
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    checked={rowData.confidential}
                    onClick={() =>
                      onInputChange(
                        'confidential',
                        index,
                        !rowData.confidential,
                      )
                    }
                  />
                  <div className={styles.checkboxLabel}>
                    <FormattedMessage id={'edit-yes'} />
                  </div>
                </div>
              </FormRow>

              <FormRow
                labelId={'restrictions'}
                required={false}
                tooltip={getTranslatedMessage('restrictions')}
              >
                <Input
                  className={styles.inputWrapper}
                  value={rowData.restrictions}
                  required={false}
                  onChange={(value) =>
                    onInputChange('restrictions', index, value)
                  }
                />
              </FormRow>

              <FormRow
                labelId={'oem-notes'}
                required={false}
                tooltip={getTranslatedMessage('oem-notes')}
              >
                <Input
                  className={styles.inputWrapper}
                  required={false}
                  value={rowData.notes}
                  onChange={(value) => onInputChange('notes', index, value)}
                />
              </FormRow>
            </div>
          ))}
        </div>

        <div className={styles.actionBtnContainer}>
          <Button
            onClick={() =>
              tableData[0].issueDate &&
              tableData[0].newDocument &&
              newDocumentName &&
              newApprovalStatus &&
              onEdit(
                tableData[0].issueDate,
                tableData[0].newDocument,
                oemId,
                productId,
              )
            }
            color={'green'}
            className={styles.uploadButton}
            disabled={
              !tableData[0].issueDate ||
              !tableData[0].newDocument ||
              !newDocumentName ||
              !newApprovalStatus
            }
          >
            <FormattedMessage id={'edit-label'} />
          </Button>

          <Button
            onClick={onClose}
            color={'transparent'}
            className={styles.cancelButton}
          >
            <FormattedMessage id={'cancel-label'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditDocumentModal;
